/*
font-family: 'Anonymous Pro', monospace;
- regular
- italic
font-family: 'Urbanist', sans-serif;
- 100
- 100 italic
- 400
- 900
font-family: 'Bebas Neue', cursive;
*/

@media screen and (max-width: 767px) {
  html {
    font-size: 15px;
  }
  .container {
    margin-right: 22.5px;
  }
  .navbar {
    right: 5px;
  }
}

@media screen and (min-width: 768px) {
  html {
    font-size: 20px;
  }
  .navbar {
    right: 15px;
  }
}

@media screen and (max-width: 991px) {
  .project-details {
    max-width: 85vw;
    max-height: 90vh;
  }
  .project-demo-laptop {
    max-width: 60%;
  }
  .project-demo-iphone {
    max-height: 30vh;
  }
  .iphone-demo-gif {
    top: 5px;
    left: 71px;
    height: 95%;
    border-radius: 15px;
  }
}

@media screen and (min-width: 992px) {
  .project-details {
    max-width: 70vw;
    max-height: 70vh;
  }
  .project-demo-laptop {
    max-width: 30vw;
  }
  .project-demo-iphone {
    max-width: 50%;
  }
  .iphone-demo-gif {
    top: 12px;
    left: 15px;
    height: 95%;
    border-radius: 15px;
  }
}

body {
  background-color: #181818;
  color: #FAFAFA;
  font-family: 'Urbanist', sans-serif;
}

a {
  color: #4cd463;
  text-decoration: none;
}

a:hover {
  color: #85e796;
}

.btn-primary {
  border: none;
  background-color: #54E86D;
  color: #181818;
}

.btn-primary:hover, .btn-primary:active {
  background-color: #4cd463 !important;
  color:#181818;
}

.btn-primary:disabled {
  background-color: #85e796;
  color: #181818;
}

.monospace {
  font-family: 'Anonymous Pro', monospace;
}

.fill-parent {
  width: 100%;
  height: 100%;
}

.bg-blend {
  background-color: #181818;
}

.bg-main {
  position: absolute;
  width: 100%;
  height: 100%;
}

.nav-link {
  font-weight: 900;
}

.section {
  z-index: 0;
  position: relative;
}

.fullscreen-overlay {
  position: fixed;
  box-sizing: border-box;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #181818a0;
}

#nav-overlay {
  background: linear-gradient(to bottom, #181818 0%,#18181800 30%);
  z-index: 100;
  opacity: 100%;
  transition: 0.2s ease-in;
}

.nav-text {
  font-size: 1.25rem;
  letter-spacing: 0.2rem;
  margin-top: 25px;
}

.navbar {
  position: fixed;
  height: 100vh;
  z-index: 1000;
  width: 22.5px;
}

.nav-button, .nav-border {
  display: block;
  height: 12.5px;
  width: 12.5px;
  box-sizing: content-box;
  border-radius: 50%;
}

.nav-button {
  background-color: #88888877;
}

.nav-border {
  margin-top: 5px;
  margin-bottom: 5px;
  border: 2.5px solid transparent;
  transition: 0.2s ease-in;
}

.nav-border:hover, .nav-button-hovered {
  border-color: #FAFAFA;
}

/* Main section */
#main {
  min-height: 100vh;
}

#title-overlay {
  box-sizing: border-box;
  z-index: -1;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, #18181800 0%,#181818 100%);
}

#bg-img {
  box-sizing: border-box;
  object-fit: cover;
  z-index: -2;
  opacity: 0.4;
}

#spinning-logo {
  aspect-ratio: 1/1;
  height: 55%;
  opacity: 0.6;
  animation: logo-spin 10s infinite linear 2s;
}

#subtitle {
  font-size: 0.9rem;
  line-height: 1.5;
  letter-spacing: 0.2rem;
}

#title-container {
  display: inline-block;
  opacity: 1;
  padding-right: 1rem;
  overflow: hidden;
  transition-timing-function: ease-in;
  transition: 0.5s;
}

#title {
  font-weight: 100;
  font-size: 3.5rem;
  letter-spacing: 0.4rem;
  line-height: 1.2;
}

#title::after {
  content: '';
  display: block;
  bottom: 0;
  left: 0;
  width: 110%;
  border-bottom: 2px solid #54E86D;
  opacity: 1;
  transform: translate3d(-110%, 0, 0);
  animation: slideInUnderline 1.5s ease-out forwards 0.5s;
}

@keyframes slideInUnderline {
  to { transform: translate3d(0, 0, 0); }
}

@keyframes logo-spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

/* About section */
#about { 
  min-height: 100vh;
  box-shadow: 0 5px 10px #888;
  clip-path: polygon(0 0, 100% 0, 100% 200%, 0 200%);
  z-index: 1;
}

#about-greeting {
  font-weight: 900;
  font-size: 3.5rem;
}

#about-name {
  color: #54E86D;
  font-size: 2.5rem;
}

#cursor {
  padding-left: 0.1em;
  border-right: 0.05em solid;
  animation: caret 1.2s steps(1) infinite;
}

@keyframes caret {
  50% {
    border-color: transparent;
  }
}

#portrait {
  border: solid #FAFAFA 5px;
  object-fit: cover;
  max-width: 90vw;
  min-height: 400px;
  height: 60vh;
  transition: 0.5s ease-out;
}

.hover-portrait {
  transform: translate(-10px, -10px);
  box-shadow: 10px 10px #54E86D;
}

#suggestion-box {
  position: relative;
  background-color: #FAFAFA;
  border-radius: 15px;
}

.about-text {
  font-weight: 300;
  font-size: 1rem;
}

/* Skills section */
#skills {
  min-height: 70vh;
  overflow: hidden;
  background-color: #DEDEDE;
}

#skills-title {
  font-weight: 900;
  font-size: 4rem;
  letter-spacing: 1rem;
  color: #181818;
}

#skills-text {
  font-weight: 200;
  font-size: 1.5rem;
  color: #181818;
}

.skill-logo {
  height: 18vh;
}

.slider-container {
  height: 18vh;
}

.slider {
  transform: translateX(0%);
  animation: scroll-skills 40s linear infinite;
}

@keyframes scroll-skills {
  to { transform: translateX(-50%); }
}

.slider-fade {
  position: absolute;
  height: 100%;
  width: 15%;
}

#left-fade {
  left: 0;
  background: linear-gradient(to left, #DEDEDE00 0%,#DEDEDE 100%);
}

#right-fade {
  right: 0;
  background: linear-gradient(to right, #DEDEDE00 0%,#DEDEDE 100%);
}

#resume-btn {
  border-radius: 1.8rem;
  font-weight: 900;
  letter-spacing: 0.2rem;
  padding: 0.3rem 3.9rem;
  font-size: 1.5rem;
  border: solid #4cd463 3px;
  background-color: #4cd463;
  color: #fafafa;
  transition: 0.25s ease-in-out;
}

#resume-btn:hover {
  border-color: #54E86D;
  background-color: #54E86D !important;
  color: #fff;
  transform: scale(1.1);
}

/* Projects section */
#projects {
  /* min-height: 100vh; */
}

#underscore {
  position: relative;
  top: -0.5rem;
  padding-left: 1.75rem;
  border-bottom: 0.1em solid;
  animation: caret 1.2s steps(1) infinite;
}

#projects-title {
  font-weight: 900;
  font-size: 3.5rem;
  letter-spacing: 0.7rem;
}

#projects-subtitle {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  transition: ease-out 0.5s;
}

#projects-wrapper div {
  flex-basis: 100%;
}

.project-icon {
  border-radius: 15px;
}

.project-card-wrapper {
  border-radius: 25px;
  transition: 0.25s ease-out;
}

.project-card {
  padding: 10px;
  padding-top: 2.5rem;
  border-radius: 25px;
  transition: 0.25s ease-out;
}

.project-card-enabled {
  border: solid 5px #DEDEDE;
}

.project-card-disabled {
  border: solid 5px #404040;
}

.project-card-title {
  font-size: 1.25rem;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding: 5px;
  margin: 0;
  transition: 0.25s ease-out;
}

.project-card-title-enabled {
  color: #181818;
  background-color: #DEDEDE;
}

.project-card-title-disabled {
  color: #888;
  background-color: #404040;
}

.project-card-wrapper:hover {
  transform: scale(1.05);
  cursor: not-allowed;
}

.project-card-wrapper:hover .project-card-enabled {
  border-color: #54E86D;
  cursor: pointer;
}

.project-card-wrapper:hover .project-card-title-enabled {
  background-color: #54E86D;
  cursor: pointer;
}

.project-card-text {
  font-size: 1rem;
  margin: 0;
}

.project-card-disabled .project-card-text {
  color: #888;
}

.wip-label {
  font-family: 'Bebas Neue', cursive;
  color: #181818;
  font-size: 0.8rem;
  letter-spacing: 0.4rem;
  background-color: #ffd000;
  position: absolute;
  width: 150px;
  transform: rotate(-30deg);
}

.wip-label-top {
  top: 0.5rem;
  left: -2rem;
}

.wip-label-bottom {
  bottom: 0.5rem;
  right: -2rem;
}

.close-details-button {
  position: absolute;
  right: 15px;
  top: 10px;
  color: #FAFAFA;
  transition: 0.1s ease-in;
}

.close-details-button:hover {
  color: #ff5757;
  cursor: pointer;
}

.project-details {
  box-sizing: border-box;
  background-color: #DEDEDE;
  border-radius: 15px;
  overflow: hidden;
  color: #181818;
}

.project-details-top-bar {
  position: relative;
  background-color: #88888888;
}

.project-details-content {
  z-index: 1;
  margin-left: 1rem;
}

.project-details-title {
  font-size: 1.25rem;
  letter-spacing: 0.05rem;
  color: #FAFAFA;
  margin: 0;
}

.project-details-description {
  font-size: 0.9rem;
}

.github-btn {
  font-size: 0.8rem;
}

.open-btn {
  margin-left: 1rem;
}

.demo {
  position: absolute;
  z-index: 1;
}

.demo-frame {
  z-index: 1;
  object-fit: contain;
}

.demo-frame-laptop {
  min-width: 100%
}

.laptop-demo-gif {
  top: 1%;
  left: 10.7%;
  width: 78.6%;
  height: 87.5%;
}

/* Contact */
#contact {
  min-height: 80vh;
}

#contact-title {
  font-size: 4.5rem;
  font-weight: 900;
  letter-spacing: 0.3rem;
  color:#4cd463;
}

#contact-subtitle {
  font-weight: 100;
  font-size: 2.5rem;
  letter-spacing: 0.1rem;
}

#contact-container {
  height: 20rem;
}

.contact-wrapper {
  color:#fafafa
}

.contact-link {
  aspect-ratio: 1 / 1;
  height: 4.2rem;
  border-radius: 15px;
  background-color: #2d2d2d;
  transition: 0.25s ease-out;
}

.contact-label {
  font-size: 1.2rem;
  font-weight: 300;
  letter-spacing: 0.05rem;
  transition: 0.25s ease-out;
}

.contact-icon {
  font-size: 2.5rem;
}

.contact-wrapper:hover {
  color: #fafafa;
  cursor: pointer;
}

.contact-wrapper:hover .contact-label {
  transform: translateY(10px);
  color:#85e796;
}

.contact-wrapper:hover .contact-link {
  transform: scale(1.2);
  box-shadow: 0 0 15px rgba(84, 232, 109, 0.8);
}

.contact-address {
  color: #FAFAFA;
  transition: 0.2s ease-in-out;
}

.contact-address:hover {
  color: #85e796;
}

/* Footer */
#footer {
  bottom: 0;
  background-color: #2d2d2d;
  width: 100%;
}

#footer-text {
  font-size: 0.8rem;
  margin: 0;
  color: #FAFAFA;
}